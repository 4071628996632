<template>
  <h1> Введение </h1>
  <p><b>Vue</b> (произносится / vjuː/, как view) - это фреймворк JavaScript для создания пользовательских
    интерфейсов.<br>
    Он построен поверх стандартных HTML, CSS и JavaScript и предоставляет декларативную компонентную модель<br>
    программирования, которая помогает вам эффективно разрабатывать пользовательские интерфейсы любой сложности.</p>
  <a href="https://otus.ru/journal/imperativnoe-i-deklarativnoe-programmirovanie/" target="_blank">Императивное и
    декларативное программирование</a><br>
  <button @click="count++">
    Count is: {{ count }}
  </button>
  <br>
  <h2>
    Прогрессивная структура
  </h2>
</template>

<script>
import {ref} from "vue";

export default {
  name: "IntroductionComponent",
  setup() {
    const count = ref(0);
    return {
      count
    }
  }
}
</script>