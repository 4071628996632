import {createApp, reactive} from 'vue'
import App from './App.vue'
import {createRouter, createWebHistory} from "vue-router";
import "./main.scss";
import {routerPaths} from "./router";


export const app_store = reactive({
    test: 'test'
})
window.app_store = app_store



if (window.navigator.userAgent === 'Bibinet IOS App' || window.navigator.userAgent.includes('Mobile')) {
    document.querySelector("html").id = "is_mobile"
} else {
    document.querySelector("html").id = "is_desktop"
}

const router = createRouter({
    history: createWebHistory(),
    routes: routerPaths
})

createApp(App).use(router).mount('#app')
