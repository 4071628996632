<template>
  <div style="display: flex; align-items: center; justify-content: center">
    <h1>{{ props.title }}</h1>
    <a v-if="props.link" style="font-size: 18px; margin-left: 12px" :href="props.link" target="_blank">🔗</a>
  </div>
</template>

<script>
export default {
  name: "PageTitle",
  props: {
    title: String,
    link: String
  },
  setup(props) {
    return {
      props
    }
  }
}
</script>