import IntroductionComponent from "./pages/IntroductionComponent.vue";
import QuickStartComponent from "./pages/QuickStartComponent.vue";
import CreatingVueComponent from "./pages/CreatingVueComponent.vue";
import StartPage from "./pages/StartPage.vue";

export const routerPaths = [
    {
        path: '/introduction',
        component: IntroductionComponent
    },
    {
        path:'/quick-start',
        component: QuickStartComponent
    },
    {
        path: '/create-vue-application',
        component: CreatingVueComponent
    },
    {
        path: '/',
        component: StartPage
    }
]

