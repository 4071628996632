<template>
  <PageTitle title="Создание приложения Vue" link="https://vuejs.org/guide/quick-start.html"></PageTitle>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";

export default {
  name: "CreatingVueComponent",
  components: {PageTitle},
  setup() {
    return {}
  }
}
</script>